import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentInit, Component, ViewEncapsulation } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom } from 'rxjs';
import { fadeInSlideUpAnimation } from 'src/app/animations/animations';
import { StatsService } from 'src/app/service/api/stats.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrl: './admin-dashboard.component.scss',
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInSlideUpAnimation()]
})
export class AdminDashboardComponent implements AfterContentInit {

  stats?: any | null;
  loading = false;

  constructor(
    private statsService: StatsService,
    private logger: NGXLogger
  ) {

  }

  ngAfterContentInit(): void {
    this.loadStats();
  }

  async loadStats() {
    this.loading = true;

    try {
      const statsResponse = await lastValueFrom(this.statsService.get());
      this.stats = statsResponse.data;
    } catch(e) {
      if (e instanceof HttpErrorResponse) {

      }
      this.logger.error('[admin-dashboard] Error retrieving stats: ' + JSON.stringify(e));
    }

    this.loading = false ;
  }

}
