import { Injectable } from '@angular/core';
import { Observable, Subject, filter } from 'rxjs';

export enum AppEventType {
  ProfileUpdatedEvent = 'PROFILE_UPDATED',
  OrganizationUpdate = 'ORGANIZATION_UPDATED'
}

export class AppEvent<T> {
  constructor(
    public type: AppEventType,
    public payload: T
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class EventQueueService {

  private eventBroker = new Subject<AppEvent<any>>();

  constructor() { }

  on(evenType: AppEventType): Observable<AppEvent<any>> {
    return this.eventBroker.pipe(filter(event => event.type === evenType));
  }

  dispatch<T>(event: AppEvent<T>): void {
    this.eventBroker.next(event);
  }
}
