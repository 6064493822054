import { CommonModule } from '@angular/common';
import { AfterContentInit, Component } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { JwtService } from 'src/app/service/utils/jwt.service';
import { TranslateModule } from '@ngx-translate/core';
import { StatsService } from 'src/app/service/api/stats.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { OrganizationDashboardComponent } from './organization-dashboard/organization-dashboard.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})

export class DashboardComponent implements AfterContentInit {
  userRole?: string | null;
  organizationId?: string | null;

  constructor(
    private jwtService: JwtService,
    private route: ActivatedRoute
  ) {
    this.organizationId = this.route.snapshot.paramMap.get('organizationId');
  }

  ngAfterContentInit(): void {
    this.userRole = this.jwtService.getUserRole();
    /* if (this.organizationId != null) {
      this.loadOrganizationStats();
    } else {
      this.loadStats();
    } */
  }

  loadStats() {
    /* this.loading = true;
    this.statsService.get().subscribe({
      next: (response) => {
        this.stats = response.data;
        this.loading = false;
      },
      error: (error) => {
        this.logger.error('[dashboard] Error loading stats: ' + error);
        this.loading = false;
      }
    }) */
  }
}
