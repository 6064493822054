import { Injectable } from '@angular/core';
import { lastValueFrom, Subject } from 'rxjs';
import { MenuChangeEvent } from './model/menuchangeevent';
import { ProfileService } from '../service/api/profile.service';
import { OrganizationService } from '../service/api/organization.service';
import { NGXLogger } from 'ngx-logger';
import { Organization } from '../model/organization.model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class MenuService {

    private menuSource = new Subject<MenuChangeEvent>();
    private resetSource = new Subject();

    menuSource$ = this.menuSource.asObservable();
    resetSource$ = this.resetSource.asObservable();

    loading = false;
    organization?: Organization | null = null;
    organizationLogo?: string | null = null;

    constructor(
        private profileService: ProfileService,
        private organizationService: OrganizationService,
        private logger: NGXLogger
    ) {
        
    }

    onMenuStateChange(event: MenuChangeEvent) {
        this.menuSource.next(event);
    }

    reset() {
        this.resetSource.next(true);
    }

    async getOrganization(organizationId: string): Promise<Organization | null | undefined> {
        if (this.organization == null || this.organization.id != organizationId) {
            
            if (this.organizationLogo != null) {
                URL.revokeObjectURL(this.organizationLogo);
                this.organizationLogo = null;
            }

            try {
                this.loading = true;
                const organizationResponse = await lastValueFrom(this.organizationService.get(organizationId));
                this.organization = organizationResponse.data;
            } catch(e) {
                if (e instanceof HttpErrorResponse) {
        
                }
                this.logger.error(`[${this.constructor.name}] Error retriving organization ` + JSON.stringify(e));
                this.organization = null;
            }
            this.loading = false;
        }

        return this.organization;
    }

    async getOrganizationLogo(organizationId: string): Promise<string | null | undefined> {
        
        if (this.organizationLogo == null || this.organization?.id != organizationId) {
            
            if (this.organizationLogo != null) {
                URL.revokeObjectURL(this.organizationLogo);
                this.organizationLogo = null;
            }
            try {
                this.loading = true;
                const organizationLogoResponse = await lastValueFrom(this.organizationService.getLogo(organizationId));
                this.organizationLogo = URL.createObjectURL(organizationLogoResponse);
            } catch (e) {
                if (e instanceof HttpErrorResponse) {  
                }
                this.logger.error(`${this.constructor.name} Error getting organization image: ` + JSON.stringify(e));
                this.organizationLogo = null;
            }
            this.loading = false;
        }

        return this.organizationLogo;
    }
}
