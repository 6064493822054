<div *ngIf="loading">
    <div class="align-items-center">
        <p-progressSpinner styleClass="w-4rem h-4rem alpha-spinner block" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>
</div>

<div [@fadeInSlideUp] *ngIf="stats" class="flex flex-column md:flex-row gap-4">
    <p-card header="{{'LABELS.clusters' | translate | titlecase}}" class="flex-1 animated-element dashboard-tile">
        <div class="flex flex-row justify-content-between align-items-center">
            <div class="flex label justify-content-center">
                <span class="flex text-7xl">{{stats.clusters}}</span>
            </div>
            
            <div class="icon">
                <i class="pi pi-server text-4xl"></i>
            </div>
            
        </div>
    </p-card>
    <p-card header="{{'LABELS.organizations' | translate | titlecase}}" class="flex-1 animated-element dashboard-tile">
        <div class="flex flex-row justify-content-between align-items-center">
            <div class="flex label justify-content-center">
                <span class="flex text-7xl">{{stats.organizations}}</span>
            </div>
            
            <div class="icon">
                <i class="pi pi-sitemap text-4xl"></i>
            </div>
            
        </div>
    </p-card>
    <p-card header="{{'LABELS.users' | translate | titlecase}}" class="flex-1 animated-element dashboard-tile">
        <div class="flex flex-row justify-content-between align-items-center">
            <div class="flex label justify-content-center">
                <span class="flex text-7xl">{{stats.users}}</span>
            </div>
            
            <div class="icon">
                <i class="pi pi-users text-4xl"></i>
            </div>
            
        </div>
    </p-card>
</div>