<div *ngIf="loading">
    <div class="align-items-center">
        <p-progressSpinner styleClass="w-4rem h-4rem alpha-spinner block" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>
</div>

<div *ngIf="organizationId != null && stats">
    <div [@fadeInSlideUp] class="grid">
        <div class="col-12 md:col-4 animated-element">
            <p-card header="{{'LABELS.users' | translate | titlecase}}" class="flex dashboard-tile">
                <div class="flex flex-row justify-content-between align-items-center">
                    <div class="flex label justify-content-center">
                        <span class="flex text-7xl">{{stats.users}}</span>
                    </div>
                    
                    <div class="icon">
                        <i class="pi pi-users text-4xl"></i>
                    </div>
                    
                </div>
            </p-card>
        </div>

        <div class="col-12 md:col-4 animated-element">
            <p-card header="{{'LABELS.assessments' | translate | titlecase}}" class="flex dashboard-tile">
                <div class="flex flex-row justify-content-between align-items-center">
                    <div class="flex label justify-content-center">
                        <span class="flex text-7xl">{{stats.assessments}}</span>
                    </div>
                    
                    <div class="icon">
                        <i class="pi pi-shield text-4xl"></i>
                    </div>
                    
                </div>
            </p-card>
        </div>
        
        <div class="col-12 md:col-4 animated-element">
            <p-card header="{{'LABELS.organizational-units' | translate | titlecase}}" class="flex dashboard-tile">
                <div class="flex flex-row justify-content-between align-items-center">
                    <div class="flex label justify-content-center">
                        <span class="flex text-7xl">{{stats['organizational-units']}}</span>
                    </div>
                    
                    <div class="icon">
                        <i class="pi pi-sitemap text-4xl"></i>
                    </div>
                    
                </div>
            </p-card>
        </div>
        
        <div class="col-12 md:col-4 animated-element">
            <p-card header="{{'LABELS.job-titles' | translate | titlecase}}" class="flex dashboard-tile">
                <div class="flex flex-row justify-content-between align-items-center">
                    <div class="flex label justify-content-center">
                        <span class="flex text-7xl">{{stats['job-titles']}}</span>
                    </div>
                    
                    <div class="icon">
                        <i class="pi pi-tags text-4xl"></i>
                    </div>
                    
                </div>
            </p-card>
        </div>
        
        <div class="col-12 md:col-4 animated-element">
            <p-card header="{{'LABELS.business-processes' | translate | titlecase}}" class="flex dashboard-tile">
                <div class="flex flex-row justify-content-between align-items-center">
                    <div class="flex label justify-content-center">
                        <span class="flex text-7xl">{{stats['business-processes']}}</span>
                    </div>
                    
                    <div class="icon">
                        <i class="pi pi-book text-4xl"></i>
                    </div>
                    
                </div>
            </p-card>
        </div>
        
        <div class="col-12 md:col-4 animated-element">
            <p-card header="{{'LABELS.documents' | translate | titlecase}}" class="flex dashboard-tile">
                <div class="flex flex-row justify-content-between align-items-center">
                    <div class="flex label justify-content-center">
                        <span class="flex text-7xl">{{ stats.documents }}</span>
                    </div>
                    
                    <div class="icon">
                        <i class="pi pi-file text-4xl"></i>
                    </div>
                    
                </div>
            </p-card>
        </div>
        
        <!-- <p-card header="{{'LABELS.documents' | translate | titlecase}}" class="col-12 md:col-4 animated-element animated-element" styleClass="bg-primary">
            <div class="flex flex-row justify-content-between">
                <span class="text-3xl">{{ stats.documents }}</span>
                <i class="pi pi-file text-5xl"></i>
            </div>
        </p-card> -->
    </div>
    <p-button *ngIf="!['organization-user'].includes(userRole!)" [label]="'LABELS.edit-organization' | translate" (onClick)="editOrganizationButtonClicked()" [outlined]=true></p-button>
</div>
